import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';

interface SizeGuideModalProps {
  isOpen: boolean;
  onClose: () => void;
  availableSizes: string[];
}

const SizeGuideModal: React.FC<SizeGuideModalProps> = ({ isOpen, onClose, availableSizes }) => {
  // Size guide data with measurements in cm
  const sizeGuideData = {
    'S': { chest: '96-101', waist: '81-86', hips: '96-101' },
    'M': { chest: '101-106', waist: '86-91', hips: '101-106' },
    'L': { chest: '106-111', waist: '91-96', hips: '106-111' },
    'XL': { chest: '111-116', waist: '96-101', hips: '111-116' },
    'XXL': { chest: '116-121', waist: '101-106', hips: '116-121' },
    'XXXL': { chest: '121-126', waist: '106-111', hips: '121-126' },
  };

  // Filter the size guide data to only show available sizes
  const filteredSizeGuideData = Object.entries(sizeGuideData)
    .filter(([size]) => availableSizes.includes(size))
    .reduce((acc, [size, measurements]) => {
      acc[size] = measurements;
      return acc;
    }, {} as typeof sizeGuideData);

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          onClick={onClose}
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="bg-white rounded-lg p-6 max-w-md w-full mx-4 shadow-xl"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">Size Guide</h2>
              <button
                onClick={onClose}
                className="text-gray-500 hover:text-gray-700 transition-colors"
              >
                <X size={20} />
              </button>
            </div>

            {availableSizes.length > 0 ? (
              <>
                <p className="text-sm text-gray-600 mb-4">
                  Measurements in centimeters (cm)
                </p>

                <div className="overflow-x-auto">
                  <table className="min-w-full border-collapse">
                    <thead>
                      <tr className="border-b">
                        <th className="py-2 px-4 text-left">Size</th>
                        <th className="py-2 px-4 text-left">Chest</th>
                        <th className="py-2 px-4 text-left">Waist</th>
                        <th className="py-2 px-4 text-left">Hips</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(filteredSizeGuideData).map(([size, measurements]) => (
                        <tr key={size} className="border-b">
                          <td className="py-2 px-4 font-medium">{size}</td>
                          <td className="py-2 px-4">{measurements.chest}</td>
                          <td className="py-2 px-4">{measurements.waist}</td>
                          <td className="py-2 px-4">{measurements.hips}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="mt-4 text-sm text-gray-600">
                  <p className="mb-2">How to measure:</p>
                  <ul className="list-disc pl-5 space-y-1">
                    <li><strong>Chest:</strong> Measure around the fullest part of your chest</li>
                    <li><strong>Waist:</strong> Measure around your natural waistline</li>
                    <li><strong>Hips:</strong> Measure around the fullest part of your hips</li>
                  </ul>
                </div>
              </>
            ) : (
              <p className="text-center py-4 text-gray-500">No size information available</p>
            )}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SizeGuideModal;
