import { useState, useEffect } from 'react';
import { ShoppingBag } from 'lucide-react';
import CartOverlay from './CartOverlay';
import { useIsMobile } from '@/hooks/use-mobile';
import { useCart } from '@/contexts/CartContext';
import { toast } from 'sonner';
import { formatPrice } from '@/lib/utils';
import { motion, AnimatePresence } from 'framer-motion';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';
import { Print } from '@/types/prints';
import SizeGuideModal from './SizeGuideModal';

// Available sizes will be loaded from each product
const genders = ['Men', 'Women'] as const;

interface OverlayControlsProps {
  showGenderControls?: boolean;
  showSizeControls?: boolean;
}

const fadeConfig = {
  initial: {
    opacity: 0,
    y: -20,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.4,
      ease: 'easeOut',
    },
  },
  exit: {
    opacity: 0,
    y: 20,
    transition: {
      duration: 0.3,
      ease: 'easeIn',
    },
  },
};

const OverlayControls: React.FC<OverlayControlsProps> = ({
  showGenderControls = true,
  showSizeControls = true,
}) => {
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [selectedSize, setSelectedSize] = useState<string | null>(null);
  const [availableSizes, setAvailableSizes] = useState<string[]>([]);
  const [selectedGender, setSelectedGender] = useState<(typeof genders)[number]>('Men');
  const [currentProductId, setCurrentProductId] = useState<string>('');
  const [selectedColor, setSelectedColor] = useState<string | null>(null);
  const [currentProductPrint, setCurrentProductPrint] = useState<string>('');
  const [showPrintSelector, setShowPrintSelector] = useState(false);
  const [showAvatarSelector, setShowAvatarSelector] = useState(false);
  const [showAddToBag, setShowAddToBag] = useState(false);
  const [showSizeGuide, setShowSizeGuide] = useState(false);
  const [productPrice, setProductPrice] = useState<number>(179); 
  const { addToCart, cartItems, selectedAvatar, setSelectedAvatar } = useCart();
  console.log('[OverlayControls] Rendering with selectedAvatar from context:', selectedAvatar);
  const isMobile = useIsMobile();

  // Hämta alla prints
  const { data: prints } = useQuery({
    queryKey: ['prints'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('prints')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      return data as Print[];
    },
  });

  // Hämta alla avatars
  const { data: avatars } = useQuery({
    queryKey: ['avatars'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('avatars')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      return data as { id: string; name: string; image_url: string }[];
    },
  });

  // Update localStorage when gender changes
  useEffect(() => {
    localStorage.setItem('selectedGender', selectedGender);
    // Dispatch event to notify other components
    window.dispatchEvent(new Event('storage'));
  }, [selectedGender]);

  // Listen for current product changes
  useEffect(() => {
    const handleStorageChange = () => {
      const productId = localStorage.getItem('currentProductId');
      const color = localStorage.getItem('selectedColor');
      const product = localStorage.getItem('currentProduct');
      const price = localStorage.getItem('currentProductPrice');

      if (productId) {
        setCurrentProductId(productId);
      }
      if (color) {
        setSelectedColor(color);
      }
      if (product) {
        try {
          const productData = JSON.parse(product);
          setCurrentProductPrint(productData.print);
          // Hämta produktpriset direkt från produktobjektet
          if (productData.price !== undefined) {
            setProductPrice(productData.price);
          }
          // Set available sizes from product data
          if (productData.sizes && Array.isArray(productData.sizes)) {
            setAvailableSizes(productData.sizes);
          } else {
            // Fallback to empty array if no sizes available
            setAvailableSizes([]);
          }
        } catch (error) {
          console.error('Error parsing product data:', error);
        }
      }
      if (price) {
        setProductPrice(parseInt(price, 10)); // Uppdatera produktpris
      }
    };

    // Set initial values
    handleStorageChange();

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  const handleSizeSelect = (size: string) => {
    setSelectedSize(size);
    setShowPrintSelector(true); // Visa print-väljaren när storlek valts
    setShowAvatarSelector(false); // Dölj avatar-väljaren
    setShowAddToBag(false); // Dölj "Add to Bag"-knappen
  };

  const handleBackToSize = () => {
    setShowPrintSelector(false);
    setShowAvatarSelector(false);
    setShowAddToBag(false);
  };

  const handlePrintSelect = (printText: string | null) => {
    // Visa bara avatar-väljaren om ett giltigt val har gjorts (inte tom sträng)
    if (printText !== null && printText !== '') {
      setCurrentProductPrint(printText);
      setShowAvatarSelector(true); // Visa avatar-väljaren
      setShowAddToBag(false); // Dölj "Add to Bag"-knappen

      // Uppdatera produkten i databasen
      if (currentProductId) {
        supabase
          .from('products')
          .update({ print: printText } as any)
          .eq('id', currentProductId)
          .then(() => {
            // Uppdatera localStorage
            const product = localStorage.getItem('currentProduct');
            if (product) {
              try {
                const productData = JSON.parse(product);
                productData.print = printText;
                localStorage.setItem('currentProduct', JSON.stringify(productData));
                // Meddela andra komponenter
                window.dispatchEvent(new Event('storage'));
              } catch (error) {
                console.error('Error updating product data:', error);
              }
            }
          });
      }
    } else {
      // Om användaren väljer "choose" (tom sträng), dölj "Add to Bag"-knappen och avatar-väljaren
      setCurrentProductPrint('');
      setShowAvatarSelector(false);
      setShowAddToBag(false);
    }
  };

  const handleAddToBag = async () => {
    if (!selectedSize) {
      toast.error('Please select a size');
      return;
    }
    if (!selectedColor) {
      toast.error('Please select a color');
      return;
    }
    if (!currentProductId) {
      toast.error('No product selected');
      return;
    }
    if (currentProductPrint === '') {
      toast.error('Please select a print option');
      return;
    }
    if (!selectedAvatar) {
      toast.error('Please select an avatar');
      return;
    }

    try {
      // Om användaren valt "no print", skicka null till databasen
      const printValue = currentProductPrint === 'no print' ? null : currentProductPrint;
      await addToCart(currentProductId, selectedSize, selectedColor, printValue, selectedAvatar);
      toast.success('Added to bag');
      setSelectedSize(null);
      setCurrentProductPrint(''); // Återställ till "choose"
      setShowPrintSelector(false); // Återställ till storleksväljaren
      setShowAvatarSelector(false); // Dölj avatar-väljaren
      setShowAddToBag(false);
    } catch (error) {
      console.error('Error adding to cart:', error);
      toast.error('Failed to add to bag');
    }
  };

  return (
    <>
      {/* Top Header Overlay - Always Visible */}
      <header className="fixed top-0 left-0 right-0 z-50">
        <div className="flex justify-between items-center w-full px-8 py-4">
          <a href="/" className="h-4">
            <span className="text-sm font-medium">PUNDEEZ</span>
          </a>
          <div className="flex items-center gap-4">
            <button
              onClick={() => setIsCartOpen(true)}
              className="flex items-center space-x-2 hover:opacity-70 transition-opacity"
            >
              <span className="text-sm">Bag ({cartItems.length})</span>
              <ShoppingBag size={20} />
            </button>
          </div>
        </div>
      </header>

      <AnimatePresence>
        {showGenderControls && (
          <motion.div
            key="gender-controls"
            initial={fadeConfig.initial}
            animate={fadeConfig.animate}
            exit={fadeConfig.exit}
            className="fixed top-16 md:top-24 left-0 right-0 z-50 flex justify-center"
          >
            {/* Gender Toggle */}
            <div className="flex gap-3 mb-8 md:mb-0">
              {genders.map(gender => (
                <motion.button
                  key={gender}
                  initial={{ scale: 0.9, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  exit={{ scale: 0.9, opacity: 0 }}
                  transition={{ duration: 0.3 }}
                  onClick={() => setSelectedGender(gender)}
                  className={`px-8 py-2 rounded-full text-sm ${
                    selectedGender === gender
                      ? 'bg-[#222222] text-white'
                      : 'bg-white text-[#222222] border border-[#222]/20 hover:border-[#222]'
                  }`}
                >
                  {gender}
                </motion.button>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showSizeControls && (
          <AnimatePresence>
            <motion.div
              key="size-controls"
              initial={fadeConfig.initial}
              animate={fadeConfig.animate}
              exit={fadeConfig.exit}
              className="fixed bottom-0 left-0 right-0 z-30 bg-transparent pointer-events-none"
            >
              <div className="w-full px-8 py-4">
                {/* Desktop layout */}
                <div className="hidden md:flex items-center justify-between pointer-events-auto">
                  <div className="flex items-center gap-6">
                    {/* Steg 1: Välj storlek */}
                    {!showPrintSelector && (
                      <div className="flex gap-3">
                        {availableSizes.length > 0 ? (
                          availableSizes.map(size => (
                          <motion.button
                            key={size}
                            initial={{ scale: 0.9, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0.9, opacity: 0 }}
                            transition={{ duration: 0.3 }}
                            onClick={() => handleSizeSelect(size)}
                            className={`w-12 h-12 rounded-full text-sm ${
                              selectedSize === size
                                ? 'bg-[#222222] text-white'
                                : 'bg-white text-[#222222] border border-[#222]/20 hover:border-[#222]'
                            }`}
                          >
                            {size}
                          </motion.button>
                        ))
                        ) : (
                          <div className="text-sm text-gray-500">No sizes available</div>
                        )}
                      </div>
                    )}

                    {/* Steg 2: Välj print */}
                    {showPrintSelector && (
                      <div className="flex gap-3 items-center">
                        <select
                          className="px-3 py-2 border border-[#222]/20 rounded-md bg-white text-[#222222]"
                          value={currentProductPrint}
                          onChange={e => {
                            const printText = e.target.value === '' ? '' : e.target.value;
                            handlePrintSelect(printText);
                          }}
                        >
                          <option value="">choose</option>
                          <option value="no print">no print</option>
                          {prints?.map(print => (
                            <option key={print.id} value={print.text}>
                              {print.text}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}

                    {/* Steg 3: Välj avatar */}
                    {showAvatarSelector && (
                      <div className="flex gap-3 items-center ml-4">
                        <select
                          className="px-3 py-2 border border-[#222]/20 rounded-md bg-white text-[#222222]"
                          value={selectedAvatar?.id || ''}
                          onChange={e => {
                            const newAvatarId = e.target.value;
                            console.log('[OverlayControls] Avatar select onChange triggered with ID:', newAvatarId);
                            if (newAvatarId === '') {
                              console.log('[OverlayControls] Setting selectedAvatar to null');
                              setSelectedAvatar(null);
                              setShowAddToBag(false); // Hide add button if no avatar
                            } else {
                              // Find the full avatar object based on the ID
                              const newAvatar = avatars?.find(a => a.id === newAvatarId);
                              if (newAvatar) {
                                console.log('[OverlayControls] Found matching avatar, setting context:', newAvatar);
                                setSelectedAvatar(newAvatar);
                                setShowAddToBag(true); // Show add button
                              } else {
                                console.error('[OverlayControls] Could not find avatar for ID:', newAvatarId);
                                setShowAddToBag(false);
                              }
                            }
                          }}
                        >
                          <option value="">choose avatar</option>
                          {avatars?.map(avatar => (
                            <option key={avatar.id} value={avatar.id}>
                              {avatar.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}

                    {/* Size guide link */}
                    <motion.button
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: -20 }}
                      transition={{ duration: 0.3 }}
                      onClick={() => !showPrintSelector && setShowSizeGuide(true)}
                      className="text-sm text-[#222]/70 hover:text-[#222] uppercase dark:text-white dark:hover:text-white/90"
                    >
                      {!showPrintSelector
                        ? 'Size Guide'
                        : showAvatarSelector
                          ? 'SELECT AVATAR'
                          : 'SELECT PRINT'}
                    </motion.button>
                  </div>

                  <div className="flex items-center gap-8">
                    <span className="text-lg">{formatPrice(productPrice)}</span>

                    {/* Steg 1: SELECT A SIZE */}
                    {!showPrintSelector && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.3 }}
                        className="text-center"
                      >
                        <h3 className="text-sm uppercase tracking-wider">SELECT A SIZE</h3>
                      </motion.div>
                    )}

                    {/* Steg 2: SELECT A PRINT */}
                    {showPrintSelector && !showAvatarSelector && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.3 }}
                        className="text-center"
                      >
                        <h3 className="text-sm uppercase tracking-wider">SELECT A PRINT</h3>
                      </motion.div>
                    )}

                    {/* Steg 3: SELECT AN AVATAR */}
                    {showAvatarSelector && !showAddToBag && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.3 }}
                        className="text-center"
                      >
                        <h3 className="text-sm uppercase tracking-wider">SELECT AN AVATAR</h3>
                      </motion.div>
                    )}

                    {/* Steg 4: ADD TO BAG */}
                    {showAddToBag && (
                      <motion.button
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.3 }}
                        onClick={handleAddToBag}
                        className="min-w-[200px] py-4 px-8 text-center bg-[#222222] text-white rounded-full hover:bg-[#222]/90"
                      >
                        ADD TO BAG
                      </motion.button>
                    )}
                  </div>
                </div>

                {/* Mobile layout */}
                <div className="md:hidden flex flex-col items-center space-y-6 pointer-events-auto">
                  {/* Steg 1: Välj storlek */}
                  {!showPrintSelector && (
                    <div className="flex flex-col items-center w-full">
                      <div className="flex gap-3 mb-6">
                        {availableSizes.length > 0 ? (
                          availableSizes.map(size => (
                          <motion.button
                            key={size}
                            initial={{ scale: 0.9, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0.9, opacity: 0 }}
                            transition={{ duration: 0.3 }}
                            onClick={() => handleSizeSelect(size)}
                            className={`w-12 h-12 rounded-full text-sm ${
                              selectedSize === size
                                ? 'bg-[#222222] text-white'
                                : 'bg-white text-[#222222] border border-[#222]/20 hover:border-[#222]'
                            }`}
                          >
                            {size}
                          </motion.button>
                        ))
                        ) : (
                          <div className="text-sm text-gray-500">No sizes available</div>
                        )}
                      </div>
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.3 }}
                        className="w-full text-center"
                      >
                        <h3 className="text-sm uppercase tracking-wider">SELECT A SIZE</h3>
                      </motion.div>
                    </div>
                  )}

                  {/* Steg 2: Välj print */}
                  {showPrintSelector && !showAvatarSelector && (
                    <div className="flex flex-col items-center w-full">
                      <div className="w-full max-w-xs mb-6">
                        <select
                          className="w-full px-3 py-2 border border-[#222]/20 rounded-md bg-white text-[#222222]"
                          value={currentProductPrint}
                          onChange={e => {
                            const printText = e.target.value === '' ? '' : e.target.value;
                            handlePrintSelect(printText);
                          }}
                        >
                          <option value="">choose</option>
                          <option value="no print">no print</option>
                          {prints?.map(print => (
                            <option key={print.id} value={print.text}>
                              {print.text}
                            </option>
                          ))}
                        </select>
                      </div>
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.3 }}
                        className="w-full text-center"
                      >
                        <h3 className="text-sm uppercase tracking-wider">SELECT A PRINT</h3>
                      </motion.div>
                    </div>
                  )}

                  {/* Steg 3: Välj avatar */}
                  {showAvatarSelector && !showAddToBag && (
                    <div className="flex flex-col items-center w-full">
                      <div className="w-full max-w-xs mb-6">
                        <select
                          className="w-full px-3 py-2 border border-[#222]/20 rounded-md bg-white text-[#222222]"
                          value={selectedAvatar?.id || ''}
                          onChange={e => {
                            const newAvatarId = e.target.value;
                            console.log('[OverlayControls] Avatar select onChange triggered with ID:', newAvatarId);
                            if (newAvatarId === '') {
                              console.log('[OverlayControls] Setting selectedAvatar to null');
                              setSelectedAvatar(null);
                              setShowAddToBag(false);
                            } else {
                              // Find the full avatar object based on the ID
                              const newAvatar = avatars?.find(a => a.id === newAvatarId);
                              if (newAvatar) {
                                console.log('[OverlayControls] Found matching avatar, setting context:', newAvatar);
                                setSelectedAvatar(newAvatar);
                                setShowAddToBag(true);
                              } else {
                                console.error('[OverlayControls] Could not find avatar for ID:', newAvatarId);
                                setShowAddToBag(false);
                              }
                            }
                          }}
                        >
                          <option value="">choose avatar</option>
                          {avatars?.map(avatar => (
                            <option key={avatar.id} value={avatar.id}>
                              {avatar.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.3 }}
                        className="w-full text-center"
                      >
                        <h3 className="text-sm uppercase tracking-wider">SELECT AN AVATAR</h3>
                      </motion.div>
                    </div>
                  )}

                  {/* Steg 4: Lägg till i varukorgen */}
                  {showAddToBag && (
                    <div className="flex flex-col items-center w-full">
                      <div className="w-full max-w-xs mb-6">
                        <select
                          className="w-full px-3 py-2 border border-[#222]/20 rounded-md bg-white text-[#222222]"
                          value={selectedAvatar?.id || ''}
                          onChange={e => {
                            const newAvatarId = e.target.value;
                            console.log('[OverlayControls] Avatar select onChange triggered with ID:', newAvatarId);
                            if (newAvatarId === '') {
                              console.log('[OverlayControls] Setting selectedAvatar to null');
                              setSelectedAvatar(null);
                              setShowAddToBag(false);
                            } else {
                              // Find the full avatar object based on the ID
                              const newAvatar = avatars?.find(a => a.id === newAvatarId);
                              if (newAvatar) {
                                console.log('[OverlayControls] Found matching avatar, setting context:', newAvatar);
                                setSelectedAvatar(newAvatar);
                                setShowAddToBag(true);
                              } else {
                                console.error('[OverlayControls] Could not find avatar for ID:', newAvatarId);
                                setShowAddToBag(false);
                              }
                            }
                          }}
                        >
                          <option value="">choose avatar</option>
                          {avatars?.map(avatar => (
                            <option key={avatar.id} value={avatar.id}>
                              {avatar.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <motion.button
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.3 }}
                        onClick={handleAddToBag}
                        className="w-full py-3 px-8 text-center bg-[#222222] text-white rounded-full hover:bg-[#222]/90"
                      >
                        ADD TO BAG
                      </motion.button>
                    </div>
                  )}
                </div>
              </div>
            </motion.div>
          </AnimatePresence>
        )}
      </AnimatePresence>

      {/* Cart Overlay */}
      <CartOverlay isOpen={isCartOpen} onClose={() => setIsCartOpen(false)} />

      {/* Size Guide Modal */}
      <SizeGuideModal 
        isOpen={showSizeGuide} 
        onClose={() => setShowSizeGuide(false)} 
        availableSizes={availableSizes} 
      />
    </>
  );
};

export default OverlayControls;
