import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { useQuery, useMutation, useQueryClient, useQueries } from '@tanstack/react-query';
import { useMemo } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';
import { formatPrice } from '@/lib/utils';
import { Database } from '@/lib/supabase/database.types.ts';

type Tables<T extends keyof Database['public']['Tables']> = Database['public']['Tables'][T]['Row'];

// Re-define explicit type for the data structure returned by the select query
type OrderItemWithProductData = Pick<
  Tables<'order_items'>,
  'id' | 'product_id' | 'quantity' | 'size' | 'color' | 'print' | 'price_at_time' | 'avatar_id'
> & {
  product: Pick<Tables<'products'>, 'name' | 'image_url'> | null;
};

interface OrderDetailsProps {
  orderId: string;
  onClose: () => void;
}

interface OrderItem {
  id: string;
  product_id: string;
  quantity: number;
  size: string;
  color: string;
  print?: string | null;
  avatar_id?: string | null;
  price_at_time: number;
  product: {
    name: string;
    image_url: string | null;
  };
}

interface OrderWithItems {
  id: string;
  created_at: string;
  customer_name: string;
  customer_email: string;
  customer_address: string;
  customer_city: string;
  customer_postal_code: string;
  customer_country: string;
  total_amount: number;
  status: string;
  payment_status: string;
  items: OrderItem[];
}

export function OrderDetails({ orderId, onClose }: OrderDetailsProps) {
  const queryClient = useQueryClient();

  const { data: order, isLoading } = useQuery({
    queryKey: ['order', orderId],
    queryFn: async () => {
      // Fetch order details
      const { data: orderData, error: orderError } = await supabase
        .from('orders')
        .select('*')
        .eq('id', orderId)
        .single();

      if (orderError) throw orderError;

      // Fetch order items with product details, using generics for type safety
      const { data: itemsData, error: itemsError } = await supabase
        .from('order_items')
        .select<
          string,
          OrderItemWithProductData // Use the explicit type here
        >(
          `
          id,
          product_id,
          quantity,
          size,
          color,
          print,
          avatar_id,
          price_at_time,
          product:products (
            name,
            image_url
          )
        `
        )
        .eq('order_id', orderId);

      if (itemsError) throw itemsError;

      // Remove ': any' and let TypeScript infer the type from the generic select
      const processedItems: OrderItem[] = (itemsData || []).map(item => {
        // Handle potential null product
        const productName = item.product?.name ?? 'Unknown Product';
        const productImageUrl = item.product?.image_url ?? null;

        return {
          id: item.id,
          product_id: item.product_id,
          quantity: item.quantity,
          size: item.size,
          color: item.color,
          print: item.print,
          avatar_id: item.avatar_id,
          price_at_time: item.price_at_time,
          product: {
            name: productName,
            image_url: productImageUrl,
          },
        };
      });

      return {
        ...orderData,
        items: processedItems,
      } as OrderWithItems;
    },
  });

  const updateStatus = useMutation({
    mutationFn: async (status: string) => {
      const { error } = await supabase.from('orders').update({ status }).eq('id', orderId);

      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['orders'] });
      queryClient.invalidateQueries({ queryKey: ['order', orderId] });
      toast.success('Order status updated successfully');
    },
    onError: (error: Error) => {
      toast.error(error.message);
    },
  });

  if (isLoading) {
    return null;
  }

  if (!order) {
    return null;
  }

  return (
    <Dialog open={true} onOpenChange={() => onClose()}>
      <DialogContent 
        className="max-w-5xl max-h-[80vh] overflow-y-auto"
        aria-describedby="order-details-description"
      >
        <DialogHeader>
          <DialogTitle>Order Details</DialogTitle>
          <p id="order-details-description" className="text-sm text-muted-foreground">
            View and manage details for this order
          </p>
        </DialogHeader>

        <div className="space-y-8 p-6">
          <div className="grid grid-cols-2 gap-8">
            <div className="space-y-2">
              <h3 className="font-semibold mb-2">Customer Information</h3>
              <p>Name: {order.customer_name}</p>
              <p>Email: {order.customer_email}</p>
              <p className="whitespace-pre-wrap">
                Address: {order.customer_address}
                <br />
                {order.customer_city}, {order.customer_postal_code}
                <br />
                {order.customer_country}
              </p>
            </div>
            <div className="space-y-2">
              <h3 className="font-semibold mb-2">Order Information</h3>
              <p>Order ID: {order.id}</p>
              <p>Date: {new Date(order.created_at).toLocaleString()}</p>
              <p>Total: {formatPrice(order.total_amount)}</p>
              <div className="flex items-center gap-2 mt-2">
                <span>Status:</span>
                <Select value={order.status} onValueChange={value => updateStatus.mutate(value)}>
                  <SelectTrigger className="w-[180px]">
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="confirmed">Confirmed</SelectItem>
                    <SelectItem value="processing">Processing</SelectItem>
                    <SelectItem value="shipped">Shipped</SelectItem>
                    <SelectItem value="delivered">Delivered</SelectItem>
                    <SelectItem value="cancelled">Cancelled</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="flex items-center gap-2 mt-2">
                <span>Payment Status:</span>
                <span
                  className={`capitalize font-medium ${order.payment_status === 'completed' ? 'text-green-500' : order.payment_status === 'pending' ? 'text-yellow-500' : 'text-red-500'}`}
                >
                  {order.payment_status}
                </span>
              </div>
            </div>
          </div>

          <div>
            <h3 className="font-semibold mb-6">Order Items</h3>
            <div className="space-y-6">
              {order.items.map(item => {
                return (
                  <div key={item.id} className="flex items-start gap-6 p-4 border rounded-lg">
                    {item.product.image_url && (
                      <img
                        src={item.product.image_url}
                        alt={item.product.name}
                        className="w-20 h-20 object-cover rounded"
                      />
                    )}
                    <div className="flex-1 flex flex-col justify-between h-full space-y-2">
                      <div>
                        <h4 className="font-medium mb-2">{item.product.name}</h4>
                        <div className="text-sm text-muted-foreground space-y-1">
                          <p>
                            <span className="font-medium text-foreground">Size:</span> {item.size}
                          </p>
                          <p>
                            <span className="font-medium text-foreground">Color:</span> {item.color}
                          </p>
                          <p>
                            <span className="font-medium text-foreground">Print:</span>{' '}
                            {item.print ? item.print : 'N/A'}
                          </p>
                          <p>
                            <span className="font-medium text-foreground">Avatar:</span>{' '}
                            {item.avatar_id ? <AvatarName avatarId={item.avatar_id} /> : 'N/A'}
                          </p>
                        </div>
                      </div>
                      <p className="text-sm mt-auto">
                        <span className="font-medium text-foreground">Quantity:</span> {item.quantity} ×{' '}
                        {formatPrice(item.price_at_time)}
                      </p>
                    </div>
                    <div className="text-right mt-auto">
                      <p className="font-medium text-lg">{formatPrice(item.quantity * item.price_at_time)}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

// Simple component to fetch and display avatar name
function AvatarName({ avatarId }: { avatarId: string }) {
  const { data: avatar } = useQuery({
    queryKey: ['avatar', avatarId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('avatars')
        .select('name')
        .eq('id', avatarId)
        .single();

      if (error) throw error;
      return data;
    },
  });

  return <>{avatar ? avatar.name : 'Loading...'}</>;
}
