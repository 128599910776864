import { Instagram, Facebook } from 'lucide-react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

// Placeholder SVG for TikTok
const TikTokIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M12.525.02c1.31-.02 2.61-.01 3.91-.02.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-2.43.05-4.86-.95-6.69-2.81-1.75-1.76-2.59-4.16-2.6-6.55-.01-2.39.61-4.78 1.99-6.62 1.33-1.79 3.28-2.91 5.35-3.07.02 2.81-.01 5.63.01 8.44.01.77-.08 1.54-.21 2.31-.21 1.2-.6 2.36-1.22 3.41-.57.97-1.34 1.8-2.26 2.51-1.84 1.44-4.62 1.16-6.3-.45-.68-.64-1.21-1.43-1.6-2.29-.31-.67-.48-1.39-.58-2.13-.02-2.81.01-5.62-.01-8.44-.02-1.52.51-3.04 1.5-4.15 1.11-1.22 2.75-1.77 4.35-1.81 1.71-.04 3.41-.03 5.12-.05z" />
  </svg>
);

// Placeholder SVG for Snapchat
const SnapchatIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm3.5 14c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm-7 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm3.5-7.01c-1.3 0-2.43.85-2.83 2.01h5.66c-.4-1.16-1.53-2.01-2.83-2.01z" />
  </svg>
);

const Footer = () => {
  return (
    <motion.footer
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1 }}
      viewport={{ once: true }}
      className="h-screen bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: 'url("/default-footer-bg.png")' }}
    >
      <div className="container mx-auto px-4 h-full flex flex-col justify-center">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          viewport={{ once: true }}
          className="flex flex-wrap justify-center gap-8 text-sm mb-8"
        >
          <motion.div whileHover={{ scale: 1.05 }} transition={{ duration: 0.2 }}>
            <Link to="/contact" className="hover:underline text-white font-mono uppercase">
              Contact
            </Link>
          </motion.div>
          <motion.div whileHover={{ scale: 1.05 }} transition={{ duration: 0.2 }}>
            <Link to="/privacy" className="hover:underline text-white font-mono uppercase">
              Privacy Policy
            </Link>
          </motion.div>
          <motion.div whileHover={{ scale: 1.05 }} transition={{ duration: 0.2 }}>
            <Link to="/terms" className="hover:underline text-white font-mono uppercase">
              Terms & Conditions
            </Link>
          </motion.div>
          <motion.div whileHover={{ scale: 1.05 }} transition={{ duration: 0.2 }}>
            <Link to="/returns" className="hover:underline text-white font-mono uppercase">
              Returns
            </Link>
          </motion.div>
          <motion.div whileHover={{ scale: 1.05 }} transition={{ duration: 0.2 }}>
            <Link to="/shipping" className="hover:underline text-white font-mono uppercase">
              Shipping
            </Link>
          </motion.div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
          viewport={{ once: true }}
          className="flex justify-center mb-8"
        >
          <div className="flex items-center gap-4">
            <a
              href="https://instagram.com/pundeez"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white hover:text-primary transition-colors"
              aria-label="Instagram"
            >
              <Instagram size={24} />
            </a>
            <a
              href="https://facebook.com/pundeez" // Replace with actual Facebook URL
              target="_blank"
              rel="noopener noreferrer"
              className="text-white hover:text-primary transition-colors"
              aria-label="Facebook"
            >
              <Facebook size={24} />
            </a>
            <a
              href="https://tiktok.com/@pundeez" // Replace with actual TikTok URL
              target="_blank"
              rel="noopener noreferrer"
              className="text-white hover:text-primary transition-colors"
              aria-label="TikTok"
            >
              <TikTokIcon className="w-6 h-6" />
            </a>
          </div>
        </motion.div>

        <motion.p
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.6 }}
          viewport={{ once: true }}
          className="text-center text-white text-sm"
        ></motion.p>
      </div>
    </motion.footer>
  );
};

export default Footer;
